
import { useTable } from '@refinedev/core';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import { format } from 'date-fns';
interface Lead {
    id: number;
    name: string;
    email: string;
    mobile_no: string;
    broadband: string;
    address: string;
    created_at:Date;
}


export default function LeadListPage() {
    const { tableQueryResult, pageCount, pageSize, current, setCurrent } = useTable<Lead>({
        resource: "leads",

    });
    console.log('fetched', tableQueryResult?.data)
    const leads = tableQueryResult?.data?.data ?? [];
    return (
        <div className='page-body'>
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card className='title-line'>
                            <CardBody>
                            <div className="table-responsive custom-scrollbar">
                                <table className="table">
                                    <thead >
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone No</th>
                                            <th>Address</th>
                                            <th>Broadband</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leads.map((post,index) => (
                                            <tr className={`border-bottom-red`} key={post.id}>
                                                <td>{index+1}</td>
                                                <td>{post.name}</td>
                                                <td>{post.email}</td> 
                                                <td>{post.mobile_no}</td>
                                                <td style={{maxWidth:'300px',whiteSpace:'wrap',wordBreak:'break-word'}}>{post.address}</td>
                                                <td>{post.broadband}</td>
                                                <td>{format(post.created_at, "dd/MM/yyyy")}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
