import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import { AgreePolicy, ChooseFile, ConfirmationText, DescriptionLabel, EmailAddress, FirstName, Password, State, Submit } from '../../../Utils/Constants';
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader';
import { Btn } from '../../../AbstractElements';
import { UseFormRegisterReturn, useForm } from "react-hook-form";
import { useGetCmsSettingQuery, useSaveCmsSettingMutation } from '../../../ReduxToolkit/Services/ApiService';
import { toast } from 'react-toastify';

export default function GoogelTagSetting() {
    const [preview, setPreview] = useState<any>();
    const { register, handleSubmit, formState: { errors }, setValue, setError, clearErrors } = useForm();
    const [formDataObj, setFormDataObj] = useState<any>()
    const [save, { isLoading }] = useSaveCmsSettingMutation()
    const response = useGetCmsSettingQuery()
    if (response.status == 'fulfilled') {
        let formdata = response.data.data;
        // setFormDataObj({ ...formdata })
        if(formdata){
            Object.keys(formdata).forEach(key => {
                if (key !== 'logo')
                    setValue(key, formdata[key]);
            });
        }
       
    }



    const saveForm = async (data: any) => {

        const formData = new FormData();

        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)  &&  data[key] &&  data[key]!=null) {
                formData.append(key, data[key]);
            }
        }

        try {
            const response = await save(formData).unwrap();
            // Handle successful mutation
            toast.success('Setting saved  successfully');
        } catch (error) {
            // Handle error
            toast.error('Failed to save setting ');
        }


    }
    const registerRs = (fieldName: string, options = {}) => {
        const registeredField: Partial<UseFormRegisterReturn> = register(fieldName, options);
        const ref = registeredField.ref;
        delete registeredField.ref;
        return { ...registeredField, innerRef: ref };
    }





    return (
        <div className="page-body">
            <Container>
                <Row>
                    <Col sm={12}>
                        <Card className=" title-line">
                            <CommonCardHeader title={'Google Tag Manager Setting'} />
                            <CardBody className="custom-input">
                                <Form onSubmit={handleSubmit(saveForm)}>
                                    <Row className="g-3">
                                        <Col xs={6}>
                                            <Label htmlFor="first-name">Header GTM Code</Label>
                                            <Input id="first-name" type="textarea" placeholder="header code"
                                                {...registerRs("header_gtm_code")}

                                            />

                                            {errors.header_gtm_code && <p className="form-error">Field is required</p>}

                                        </Col>

                                        <Col xs={6}>
                                            <Label htmlFor="first-name">Footer GTM Code</Label>
                                            <Input type="textarea" id="first-name"  placeholder="footer code"
                                                {...registerRs("footer_gtm_code")}

                                            />

                                            {errors.footer_gtm_code && <p className="form-error">Field is required</p>}

                                        </Col>





                                        <Col xs={12}>
                                            <Btn color="primary" size={'sm'} className="btn-square pull-right">
                                                {isLoading ? 'Loading' : 'Submit'}</Btn>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>


    )
}
