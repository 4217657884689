import { Refine, AuthProvider } from "@refinedev/core";
import axios from "axios";
const handleLogin = () => {
  return false
}

const API_URL = process.env.REACT_APP_SERVER_URL;
export const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    let response = await axios.post(API_URL + 'api/auth/login', {
      email,
      password
    });
  
    if (response.status >= 200 && response.status < 300) {
      let resp = response.data;
     localStorage.setItem("token",resp['authorisation']['token'] );
      localStorage.setItem("user", JSON.stringify({name:resp['user']['name'],email:resp['user']['email']}));
     
    
      return { success: true};
    }
    else {
      return {
        success: false,
        error: { name: "Login Error", message: "Invalid credentials" },
      };
    }





  },
  check: async (params) => {
    const token = localStorage.getItem("token") ;
  
    if (token) {
      return {
        authenticated: true,
        redirectTo: "/dashboard",
      };
    }

    return {
      authenticated: false,
      logout: true,
      redirectTo: "/login",
      error: {
        message: "Check failed",
        name: "Unauthorized",
      },
    };
  },
  logout: async (params) => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  onError: async (params) => ({}),
  register: async (params) => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  forgotPassword: async (params) => ({
    success: true,
    redirectTo: "/login",
  }),
  updatePassword: async (params) => ({
    success: true,
    redirectTo: "/login",
  }),
  getPermissions: async (params) => ({}),
  getIdentity: async (params) => ({}),
};

