import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader';
import { Btn } from '../../../AbstractElements';
import { UseFormRegisterReturn, useForm } from "react-hook-form";
import { useGetCmsSettingQuery, useSaveCmsSettingMutation } from '../../../ReduxToolkit/Services/ApiService';
import { toast } from 'react-toastify';

export default function SeoSetting() {
    const { register, handleSubmit, formState: { errors }, setValue, setError, clearErrors } = useForm();

    const [save, { isLoading }] = useSaveCmsSettingMutation()
    const response = useGetCmsSettingQuery()
    if (response.status == 'fulfilled') {
        let formdata = response.data.data;
        // setFormDataObj({ ...formdata })
         if(formdata){
        Object.keys(formdata).forEach(key => {
            if (formdata[key])
                setValue(key, formdata[key]);
        });
    }
    }



    const saveForm = async (data: any) => {


        const formData = new FormData();

        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                formData.append(key, data[key]);
            }
        }

        try {
            const response = await save(formData).unwrap();
            // Handle successful mutation
            toast.success('Seo Setting saved  successfully');
        } catch (error) {
            // Handle error
            toast.error('Failed to save setting ');
        }


    }
    const registerRs = (fieldName: string, options = {}) => {
        const registeredField: Partial<UseFormRegisterReturn> = register(fieldName, options);
        const ref = registeredField.ref;
        delete registeredField.ref;
        return { ...registeredField, innerRef: ref };
    }


    return (
        <div className="page-body">
            <Container>
                <Row>
                    <Col sm={12}>
                        <Card className=" title-line">
                            <CommonCardHeader title={'SEO Setting'} />
                            <CardBody className="custom-input">
                                <Form onSubmit={handleSubmit(saveForm)}>
                                    <Row className="g-3">
                                        <Col xs={12}>
                                            <Label htmlFor="first-name">Meta Keywords</Label>
                                            <Input id="first-name" type="textarea" placeholder="Meta keywords"
                                                {...registerRs("meta_keywords")}

                                            />



                                        </Col>

                                        <Col xs={12}>
                                            <Label htmlFor="first-name">Meta Description</Label>
                                            <Input type="textarea" placeholder="Meta Description"
                                                {...registerRs("meta_description")}

                                            />

                                        </Col>





                                        <Col xs={12}>
                                            <Btn color="primary" size={'sm'} className="pull-right btn-square">
                                                {isLoading ? 'Loading' : 'Submit'}</Btn>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>


    )
}
