import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import { AgreePolicy, ChooseFile, ConfirmationText, DescriptionLabel, EmailAddress, FirstName, Password, State, Submit } from '../../../Utils/Constants';
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader';
import { Btn } from '../../../AbstractElements';
import { UseFormRegisterReturn, useForm } from "react-hook-form";
import { useGetCmsSettingQuery, useSaveCmsSettingMutation } from '../../../ReduxToolkit/Services/ApiService';
import { toast } from 'react-toastify';

export default function SliderBannerSetting() {
    const [previewBanner, setPreviewBanner] = useState<any>();
    const [previewSliderImage1, setPreviewSliderImage1] = useState<any>();
    const [previewSliderImage2, setPreviewSliderImage2] = useState<any>();
    const [previewSliderImage3, setPreviewSliderImage3] = useState<any>();
    const [previewSliderImage4, setPreviewSliderImage4] = useState<any>();

    const { register, handleSubmit, formState: { errors }, setValue, setError, clearErrors } = useForm();
    const [formDataObj, setFormDataObj] = useState<any>()
    const [save, { isLoading }] = useSaveCmsSettingMutation()
    const response = useGetCmsSettingQuery()
   if (response.status == 'fulfilled') {
        let formdata = response.data.data;
        // setFormDataObj({ ...formdata })
     
       
    }



    const saveForm = async (data: any) => {

        data.banner = data['banner'][0]
        data.slider_image1 = data['slider_image1'][0]
        data.slider_image2 = data['slider_image2'][0]
        data.slider_image3 = data['slider_image3'][0]
        data.slider_image4 = data['slider_image4'][0]

        const formData = new FormData();

        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key) && data[key]) {
                formData.append(key, data[key]);
            }
        }

        try {
            const response = await save(formData).unwrap();
            // Handle successful mutation
            toast.success('Setting saved  successfully');
        } catch (error) {
            // Handle error
            toast.error('Failed to save setting ');
        }


    }
    const registerRs = (fieldName: string, options = {}) => {
        const registeredField: Partial<UseFormRegisterReturn> = register(fieldName, options);
        const ref = registeredField.ref;
        delete registeredField.ref;
        return { ...registeredField, innerRef: ref };
    }



    const handleFileChange = (e: any, field: any) => {
        const file = e.target.files[0];
        const maxSize = 2 * 1024 * 1024; // 5MB
        if (file.size > maxSize) {
            setError(field, {
                type: 'manual',
                message: 'File size should be less than 2MB'
            });
            setValue(field, null)
            return;
        }
        else if (!['image/png', 'image/gif', 'image/jpeg'].includes(file.type)) {
            setError(field, {
                type: 'manual',
                message: 'Only Image file allowed'
            });
            setValue(field, null)
            return;
        }
        clearErrors(field);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                switch (field) {
                    case 'banner':
                        setPreviewBanner(reader.result);
                        break;
                    case 'slider_image1':
                        setPreviewSliderImage1(reader.result);
                        break;
                    case 'slider_image2':
                        setPreviewSliderImage2(reader.result);
                        break;
                    case 'slider_image3':
                        setPreviewSliderImage3(reader.result);
                        break;
                    case 'slider_image4':
                        setPreviewSliderImage4(reader.result);
                        break;
                    default:


                }

            };
            reader.readAsDataURL(file);
        }
    };

    const renderImage = (field: any) => {
        switch (field) {
            case 'banner':
                if (previewBanner) {
                    return <img src={previewBanner} width='80' height='80' />
                }
                else {
                    if (response['data'] && response['data']['data'][field])
                        return <img src={`${process.env.REACT_APP_SERVER_URL}storage/${field}/${response['data']['data'][field]}`} width='80' height='80' />
                    else
                        return " "

                }
                break;
            case 'slider_image1':
                if (previewSliderImage1) {
                    return <img src={previewSliderImage1} width='80' height='80' />
                }
                else {
                    if (response['data'] && response['data']['data'][field])
                        return <img src={`${process.env.REACT_APP_SERVER_URL}storage/${field}/${response['data']['data'][field]}`} width='80' height='80' />
                    else
                        return " "

                }
                break;
            case 'slider_image2':
                if (previewSliderImage2) {
                    return <img src={previewSliderImage2} width='80' height='80' />
                }
                else {
                    if (response['data'] && response['data']['data'][field])
                        return <img src={`${process.env.REACT_APP_SERVER_URL}storage/${field}/${response['data']['data'][field]}`} width='80' height='80' />
                    else
                        return " "

                }
                break;
            case 'slider_image3':
                if (previewSliderImage3) {
                    return <img src={previewSliderImage3} width='80' height='80' />
                }
                else {
                    if (response['data'] && response['data']['data'][field])
                        return <img src={`${process.env.REACT_APP_SERVER_URL}storage/${field}/${response['data']['data'][field]}`} width='80' height='80' />
                    else
                        return " "

                }
                break;
            case 'slider_image4':
                if (previewSliderImage4) {
                    return <img src={previewSliderImage4} width='80' height='80' />
                }
                else {
                    if (response['data'] && response['data']['data'][field])
                        return <img src={`${process.env.REACT_APP_SERVER_URL}storage/${field}/${response['data']['data'][field]}`} width='80' height='80' />
                    else
                        return " "

                }
                break;
            default:


        }


    }

    return (
        <div className="page-body">
            <Container>
                <Row>
                    <Col sm={12}>
                        <Card className=" title-line">
                            <CommonCardHeader title={'Slider/Banner Setting'} />
                            <CardBody className="custom-input">
                                <Form onSubmit={handleSubmit(saveForm)}>
                                    <Row className="g-3">





                                        <Col xs={12}>
                                            <Label htmlFor="formFile">Banner (Max Size:2MB)</Label>
                                            <Input id="formFile" type="file"  {...registerRs("banner")} onChange={(e: any) => handleFileChange(e, 'banner')} />
                                            {errors.logo && <p className="form-error" dangerouslySetInnerHTML={{ __html: `<span>${errors.banner?.message}</span>` }} />}
                                            {renderImage('banner')}

                                        </Col>
                                        <Col xs={6}>
                                            <Label htmlFor="formFile">Slider Image1 (Max Size:2MB)</Label>
                                            <Input id="formFile" type="file"  {...registerRs("slider_image1")} onChange={(e: any) => handleFileChange(e, 'slider_image1')} />
                                            {errors.logo && <p className="form-error" dangerouslySetInnerHTML={{ __html: `<span>${errors.slider_image1?.message}</span>` }} />}
                                            {renderImage('slider_image1')}

                                        </Col>
                                        <Col xs={6}>
                                            <Label htmlFor="formFile">Slider Image2 (Max Size:2MB)</Label>
                                            <Input id="formFile" type="file"  {...registerRs("slider_image2")}
                                                onChange={(e: any) => handleFileChange(e, 'slider_image2')} />
                                            {errors.logo && <p className="form-error" dangerouslySetInnerHTML={{ __html: `<span>${errors.slider_image2?.message}</span>` }} />}
                                            {renderImage('slider_image2')}

                                        </Col>
                                        <Col xs={6}>
                                            <Label htmlFor="formFile">Slider Image3 (Max Size:2MB)</Label>
                                            <Input id="formFile" type="file"  {...registerRs("slider_image3")} onChange={(e: any) => handleFileChange(e, 'slider_image3')} />
                                            {errors.logo && <p className="form-error" dangerouslySetInnerHTML={{ __html: `<span>${errors.slider_image3?.message}</span>` }} />}
                                            {renderImage('slider_image3')}

                                        </Col>
                                        <Col xs={6}>
                                            <Label htmlFor="formFile">Slider Image4 (Max Size:2MB)</Label>
                                            <Input id="formFile" type="file"  {...registerRs("slider_image4")} onChange={(e: any) => handleFileChange(e, 'slider_image4')} />
                                            {errors.logo && <p className="form-error" dangerouslySetInnerHTML={{ __html: `<span>${errors.slider_image4?.message}</span>` }} />}
                                            {renderImage('slider_image4')}

                                        </Col>







                                        <Col xs={12}>
                                            <Btn color="primary" size={'sm'} className="pull-right btn-square">
                                                {isLoading ? 'Loading' : 'Submit'}</Btn>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>


    )
}
