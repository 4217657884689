// Disable the warning about defaultProps removal
// eslint-disable-next-line react/require-default-props
import { UseFormRegisterReturn, useForm } from "react-hook-form";
import { Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { toast } from 'react-toastify';
import { Btn } from "../../../AbstractElements";
import { useSaveAreaMutation, useUpdateAreaMutation } from "../../../ReduxToolkit/Services/ApiService";
export default function EditModal({ editData, isOpen, toggle, onItemUpdated }: any) {
   
    const { register, handleSubmit, formState: { errors }, setValue, setError, clearErrors, reset } = useForm();
    const [save, { isLoading }] = useUpdateAreaMutation()
    const saveForm = async (data: any) => {
       
       
        try {
            
            const response = await save({data,id:editData.id}).unwrap();
            onItemUpdated(data)
            toggle()

            toast.success('Area updated  successfully');
        } catch (error) {
            toast.error('Failed to save area ',);
        }


    }
    const registerRs = (fieldName: string, options = {}) => {
        const registeredField: Partial<UseFormRegisterReturn> = register(fieldName, options);
        const ref = registeredField.ref;
        delete registeredField.ref;
        return { ...registeredField, innerRef: ref };
    }
    if(editData){
        setValue('name',editData.name)
        setValue('connection_type',editData.connection_type)
    }
    return <>{editData && <Modal isOpen={isOpen} toggle={toggle} centere={'center'} size='md'>

        <ModalBody className={'dark-sign-up social-profile text-start'}>
            <ModalHeader toggle={toggle}>
                Edit Area
            </ModalHeader>
            <Form className="theme-form dark-inputs px-3 pt-3">
                <Row className="g-3">
                    <Col xs={12}>
                        <Label htmlFor="first-name">Name</Label>
                        <Input id="first-name" className="btn-square" type="text" placeholder="Meta keywords" 
                            {...registerRs("name")}

                        />



                    </Col>

                    <Col xs={12}>
                        <Label>Connection Type</Label>
                        <Input  className="btn-square" type="select"  {...registerRs("connection_type")}>
                            <option value="Wireless">Wireless</option>
                            <option value="Fiber">Fiber</option>

                        </Input>

                    </Col>






                </Row>
            </Form>
        </ModalBody>
        <ModalFooter>
            <Btn color='secondary ' className="btn-sm btn-square" onClick={toggle}>Close</Btn>
            <Btn color='primary' className="btn-sm btn-square" onClick={handleSubmit(saveForm)}>{isLoading ? 'Wait..' : 'Save'}</Btn>
        </ModalFooter>
    </Modal >}</>
}
