import React, { useEffect } from 'react';
import Routers from './Routes';
import { ToastContainer } from 'react-toastify';
import { Authenticated, Refine } from "@refinedev/core";
import { dataProvider } from './providers/data_provider';
import routerProvider, { CatchAllNavigate } from "@refinedev/react-router-v6";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import PrivateRoutes from './Routes/PrivateRoutes';
import LayoutRoutes from './Routes/LayoutRoutes';
import SignIn from './Auth';
import { authProvider } from './providers/authProvider';
import SignInForm from './Auth/SignInForm';
import MenuBuilder from './Components/Pages/MenuBuilder';
import CmsForm from './Components/Pages/CMS/general_setting';
import DashboardPage from './Components/Pages/DashboardPage';
import Layout from './Layout/Layout';
import GoogelTagSetting from './Components/Pages/CMS/google_tag_setting';
import Slider from 'react-slick';
import SliderBannerSetting from './Components/Pages/CMS/banner_setting';
import SeoSetting from './Components/Pages/CMS/seo';
import LeadListPage from './Components/Pages/leads/lead_list';

import AreaListPage from './Components/Pages/area/list';
import PlanListPage from './Components/Pages/plan/list';


function App() {

  return (
    <Refine dataProvider={dataProvider} authProvider={authProvider}
      resources={[
        {
          name: "leads",
          list: LeadListPage,

        },
        {
          name: "areas",
          list: AreaListPage,
       
        },
        {
          name: "plans",
          list: PlanListPage,

        },
      ]}
    >
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={
            <Authenticated key="authenticated-routes" fallback={<CatchAllNavigate to="/login" />}>
              <Layout />
            </Authenticated>


          }>
            <Route path='dashboard' element={<DashboardPage />} />
            <Route path='leads' element={<LeadListPage />} />
            <Route path='cms' >
              <Route index path='menu_builder' element={<MenuBuilder />} />


              <Route path='general_setting' element={<CmsForm />} />
              <Route path='gtm_setting' element={<GoogelTagSetting />} />
              <Route path='slider_banner_setting' element={<SliderBannerSetting />} />
              <Route path='seo_setting' element={<SeoSetting />} />

            </Route>
            <Route path='broadband' >
              {/* <Route index path='bill' element={<MenuBuilder />} /> */}


              <Route path='plan' element={<PlanListPage />} />
              {/* <Route path='customer' element={<GoogelTagSetting />} /> */}
              <Route path='area' element={<AreaListPage />} />


            </Route>



          </Route>
          <Route
            element={
              <Authenticated
                key="authenticated-pages"
                fallback={<Outlet />}
              >
                <Navigate to="/dashboard" />
              </Authenticated>
            }
          >
            <Route path={`/login`} element={<SignInForm />} /></Route>
        </Routes>
      </BrowserRouter>

      <ToastContainer />
    </Refine>
  );
}

export default App;
