// Disable the warning about defaultProps removal
// eslint-disable-next-line react/require-default-props
import { UseFormRegisterReturn, useForm } from "react-hook-form";
import { Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { toast } from 'react-toastify';
import { Btn } from "../../../AbstractElements";
import { useSaveAreaMutation, useUpdateAreaMutation, useUpdatePlanMutation } from "../../../ReduxToolkit/Services/ApiService";
export default function EditModal({ editData, isOpen, toggle, onItemUpdated }: any) {

    const { register, handleSubmit, formState: { errors }, setValue, setError, clearErrors, reset } = useForm();
    const [save, { isLoading }] = useUpdatePlanMutation()
    const saveForm = async (data: any) => {
        

        try {
     
            const response = await save({ data, id: editData.id }).unwrap();
            onItemUpdated(data)
            toggle()

            toast.success('Area updated  successfully');
        } catch (error) {
            toast.error('Failed to save area ',);
        }


    }
    const registerRs = (fieldName: string, options = {}) => {
        const registeredField: Partial<UseFormRegisterReturn> = register(fieldName, options);
        const ref = registeredField.ref;
        delete registeredField.ref;
        return { ...registeredField, innerRef: ref };
    }
    if (editData) {
        setValue('name', editData.name)
        setValue('speed', editData.speed)
        setValue('validity', editData.validity)
        setValue('amount', editData.amount)
        setValue('type', editData.type)
        setValue('plan_type', editData.plan_type)
    }
    return <>{editData && <Modal isOpen={isOpen} toggle={toggle} centere={'center'} size='md'>

        <ModalBody className={'dark-sign-up social-profile text-start'}>
            <ModalHeader toggle={toggle}>
                Edit Plan
            </ModalHeader>
            <Form className="theme-form dark-inputs px-3 pt-3">
                <Row className="g-3">
                    <Col xs={12}>
                        <Label htmlFor="first-name">Name</Label>
                        <Input id="first-name" className="btn-square" type="text" placeholder=" name"
                            {...registerRs("name")}

                        />



                    </Col>
                  
                    <Col xs={12}>
                        <Label htmlFor="first-name">Speed</Label>
                        <Input className="btn-square" type="text" placeholder="speed"
                            {...registerRs("speed")}

                        />



                    </Col>
                    <Col xs={12}>
                        <Label htmlFor="first-name">Validity</Label>
                        <Input className="btn-square" type="text" placeholder="validity"
                            {...registerRs("validity")}

                        />



                    </Col>
                    <Col xs={12}>
                        <Label htmlFor="first-name">Amount</Label>
                        <Input className="btn-square" type="number" placeholder="amount"
                            {...registerRs("amount")}

                        />



                    </Col>

                    <Col xs={12}>
                        <Label> Type</Label>
                        <Input className="btn-square" type="select"  {...registerRs("type")}>
                            <option value="Internet">Only Internet</option>
                            <option value="Tv+OTT+Internet">Tv+OTT+Internet</option>
                            <option value="Free Router ">Free Router Plan</option>

                        </Input>

                    </Col>
                    <Col xs={12}>
                        <Label>Plan Type</Label>
                        <Input className="btn-square"  type="select"  {...registerRs("plan_type")}>
                           
                            <option value="Monthly">Monthly</option>
                            <option value="HalfYearly">6 Months</option>
                            <option value="Yearly">12 Months</option>

                        </Input>

                    </Col>
                </Row>
            </Form>
        </ModalBody>
        <ModalFooter>
            <Btn color='secondary ' className="btn-sm btn-square" onClick={toggle}>Close</Btn>
            <Btn color='primary' className="btn-sm btn-square" onClick={handleSubmit(saveForm)}>{isLoading ? 'Wait..' : 'Save'}</Btn>
        </ModalFooter>
    </Modal >}</>
}
