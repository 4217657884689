import React, { FormEvent, useState } from 'react'
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Btn, H2, H6, Image, P } from '../AbstractElements';
import { dynamicImage } from '../Utils';

import { CreateAccount, DontHaveAccount, EmailAddress, ForgotPassword, Password, RememberPassword, SignIn, SignInAccount, SignInWith } from '../Utils/Constants';
import SocialLink from './SocialLink';
import axios from 'axios';
import { AuthPage, useLogin } from '@refinedev/core';
export default function SignInForm() {
    const { mutate: login, isLoading } = useLogin<{ email: string, password: string }>();
    const [isPasswordVisible, setPasswordVisible] = useState(false);
    const [email, setEmail] = useState("test123@gmail.com");
    const [password, setPassword] = useState("Test@123");
    const toggle = () => setPasswordVisible(!isPasswordVisible);
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            // Call the handleLogin function and await its result
            await login({
                email: email,
                password: password,
            });
        } catch (error:any) {
            console.error(`Unhandled dfbdfb Error in login: ${error?.message}`);
            // Handle the error here, such as displaying an error message to the user
        }
        

    };
    return <AuthPage
        type="login"

        renderContent={(content: React.ReactNode) => {
            return (<Container className="p-0" fluid>
                <Row className="m-0">
                    <Col xs={12} className="p-0">
                        <div className="login-card login-dark">
                            <div>
                                <div>
                                    <Link className='logo' to={`/`}>
                                        <Image className="img-fluid for-light" src={dynamicImage(`logo.png`)} alt="looginpage" />
                                        <Image className="img-fluid for-dark" src={dynamicImage(`logo.png`)} alt="looginpage"></Image>
                                    </Link>
                                </div>
                                {/* <h1>App URL: {import.meta.env.VITE_SECRET}</h1> */}
                                <div className="login-main">
                                    <Form className="theme-form" onSubmit={onSubmit}>
                                        <H2>{SignInAccount}</H2>
                                        <P>{"Enter your email & password to login"}</P>
                                        <FormGroup>
                                            <Col><Label>{EmailAddress}</Label></Col>
                                            <Input type="email" defaultValue={email} onChange={(event) => setEmail(event.target.value)} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Col><Label>{Password}</Label></Col>
                                            <div className="form-input position-relative">
                                                <Input type={isPasswordVisible ? 'text' : 'password'} defaultValue={password} onChange={(event) => setPassword(event.target.value)} name="login[password]" />
                                                <div className='show-hide' onClick={toggle}>
                                                    <span className={!isPasswordVisible ? 'show' : ''}></span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <div className="mb-0 form-group">
                                            {/* <div className="checkbox p-0">
                                    <Input id="checkbox1" type="checkbox" />
                                    <Label className="text-muted" htmlFor="checkbox1">{RememberPassword}</Label>
                                </div>
                                <Link className="link" to={`/auth/forget-password`}>{ForgotPassword}</Link> */}
                                            <div className="text-end mt-3">
                                                <Btn color='primary' className="w-100" block>{isLoading ? 'Please Wait..' : SignIn}
                                                </Btn>
                                            </div>
                                        </div>


                                    </Form>
                                </div>
                            </div >
                        </div>
                    </Col>
                </Row>
            </Container>

            )
        }
        }
    />
}
