
import { HttpError, useDelete, useList, useTable } from '@refinedev/core';
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Popover, PopoverBody, PopoverHeader, Row } from 'reactstrap'
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import CreateModal from './create';
import { ArrowDown } from 'react-feather';
import { Btn } from '../../../AbstractElements';
import EditModal from './edit';
import { DeleteButton } from './deleteBtn';
import { toast } from 'react-toastify';
interface Plan {
    id?: number;
    name: string;
    speed: string;
    validity: string;
    type: string;
    amount: number;
    created_at: Date;
}


export default function PlanListPage() {
    const [isOpen, setIsOpen] = useState(false);

    const [isEditOpen, setIsEditOpen] = useState(false);
    const [editItem, setEditItem] = useState<Plan>();
    const [list, setList] = useState<Plan[]>([])
    const toggle = () => setIsOpen(!isOpen);
    const toggleEdit = () => setIsEditOpen(!isEditOpen);
    const { mutate, data: deleteInfo } = useDelete();

    const { data, isLoading, isError } = useList<Plan, HttpError>({
        resource: "plans",
    });

    if (list.length == 0 && data?.data && data?.data.length > 0) {

        setList(data?.data as Plan[])
    }

    const handleDelete = ((item_id: any) => {
        mutate(
            {
                resource: "plans",
                id: item_id,
            },
            {
                onError: (error, variables, context) => {
                    toast.error('Some error ocured')
                },
                onSuccess: (data, variables, context) => {
                    toast.success('Deleted Successfully')
                },
            },
        );
        let n_list = list.filter((item: any) => {
            return item.id != item_id;

        })
        setList(n_list);
    })
    console.log('times is l', list)
    const editingItem = (item: Plan) => {
        toggleEdit();
        setEditItem({ ...item });
    }
    const handleItemAdded = (item: Plan) => {
        setList((list) => {
            let g = { ...item, created_at: new Date() } as Plan;
            list.unshift(g);
            return [...list];
        })

    }
    const handleItemUpdated = (item: any) => {

        setList((list) => {
            let g = list.map(obj => {
                if (obj.id === editItem!['id']) {
                    return { ...obj, ...item };
                }
                return obj;
            });
            console.log('new g', g)
            return [...g];
        })

    }

    return (
        <div className='page-body'>
            <CreateModal isOpen={isOpen} toggle={toggle} onItemAdd={handleItemAdded} />
            <EditModal editData={editItem} isOpen={isEditOpen} toggle={toggleEdit} onItemUpdated={handleItemUpdated} />
            <Container fluid>
                <Row>

                    <Col sm={12}>
                        <Card className='title-line'>
                            <CardBody>
                                <Row className="justify-content-between">
                                    <Col sm={4}>
                                        <Form >
                                            <div className="d-flex gap-2 align-items-center">

                                                <h6 style={{ display: 'inline' }} >
                                                    Search
                                                </h6>

                                                <Input className="btn-square"
                                                    id="exampleEmail"
                                                    name="email"
                                                    placeholder="Search by name"
                                                    type="text"
                                                />
                                            </div>
                                        </Form>

                                    </Col>
                                    <Col sm={4}>
                                        <Button color="primary" className="pull-right btn-primary btn-sm btn-square " onClick={toggle}>
                                            <i className="fa fa-plus"></i>&nbsp;&nbsp;Create</Button>
                                    </Col>
                                </Row>



                                <div className="table-responsive custom-scrollbar mt-3">
                                    <table className="table">
                                        <thead >
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Speed</th>
                                                <th>Validity</th>
                                                <th>Amount</th>
                                                <th>Type</th>

                                                <th>Date</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list && list.length > 0 && list.map((post, index) => (
                                                <tr className={`border-bottom-red`} key={post.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{post.name}</td>
                                                    <td>{post.speed}</td>
                                                    <td>{post.validity}</td>
                                                    <td>{post.amount}</td>
                                                    <td>{post.type}</td>

                                                    <td>{format(post.created_at, "dd/MM/yyyy")}</td>
                                                    <td>
                                                        <ButtonGroup >
                                                            <Btn color='primary' outline={true} >
                                                                <i className='fa fa-eye'></i></Btn>
                                                            <Btn color='warning' onClick={(e: any) => editingItem(post)} outline={true} >
                                                                <i className='fa fa-pencil'></i></Btn>
                                                            <DeleteButton item_id={post.id} key={post.id} deleteCallback={handleDelete} />


                                                        </ButtonGroup>

                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
