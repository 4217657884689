import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import CommonCardHeader from '../../../Utils/CommonComponents/CommonCardHeader';
import { Btn } from '../../../AbstractElements';
import { UseFormRegisterReturn, useForm } from "react-hook-form";
import { useGetCmsSettingQuery, useSaveCmsSettingMutation } from '../../../ReduxToolkit/Services/ApiService';
import { toast } from 'react-toastify';

export default function GeneralSetting() {
    const [preview, setPreview] = useState<any>();
    const [previewFavicon, setPreviewFavicon] = useState<any>();
    const { register, handleSubmit, formState: { errors }, setValue, setError, clearErrors } = useForm();

    const [save, { isLoading }] = useSaveCmsSettingMutation()
    const response = useGetCmsSettingQuery()
    if (response.status == 'fulfilled') {
        let formdata = response.data.data;
        // setFormDataObj({ ...formdata })
        if (formdata) {
            Object.keys(formdata).forEach(key => {
                if (key !== 'logo' && key !== 'favicon')
                    setValue(key, formdata[key]);
            });
        }

    }



    const saveForm = async (data: any) => {

        data.logo = data['logo'][0]
        data.favicon = data['favicon'][0] === undefined ? null : data['favicon'][0]
        // console.log(data)
        if (data.show_google_reviews)
            data.show_google_reviews = 'Yes'
        else
            data.show_google_reviews = 'No'
        const formData = new FormData();

        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key) && data[key] && data[key] != null) {
                formData.append(key, data[key]);
            }
        }

        try {
            const response = await save(formData).unwrap();
            // Handle successful mutation
            toast.success('Setting saved  successfully');
        } catch (error) {
            // Handle error
            toast.error('Failed to save setting ');
        }


    }
    const registerRs = (fieldName: string, options = {}) => {
        const registeredField: Partial<UseFormRegisterReturn> = register(fieldName, options);
        const ref = registeredField.ref;
        delete registeredField.ref;
        return { ...registeredField, innerRef: ref };
    }



    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        const maxSize = 1 * 1024 * 1024; // 5MB
        if (file.size > maxSize) {
            setError('logo', {
                type: 'manual',
                message: 'File size should be less than 1MB'
            });
            setValue('logo', null)
            return;
        }
        else if (!['image/png', 'image/gif', 'image/jpeg'].includes(file.type)) {
            setError('logo', {
                type: 'manual',
                message: 'Only Image file allowed'
            });
            setValue('logo', null)
            return;
        }
        clearErrors('logo');
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleFaviconFileChange = (e: any) => {
        const file = e.target.files[0];
        const maxSize = 0.5 * 1024 * 1024; // 5MB
        if (file.size > maxSize) {
            setError('favicon', {
                type: 'manual',
                message: 'File size should be less than 500KB'
            });
            setValue('favicon', null)
            return;
        }
        else if (!['image/png', 'image/gif', 'image/jpeg'].includes(file.type)) {
            setError('favicon', {
                type: 'manual',
                message: 'Only Image file allowed'
            });
            setValue('favicon', null)
            return;
        }
        clearErrors('favicon');
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewFavicon(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const renderImage = (field: any) => {
        switch (field) {
            case 'logo':
                if (preview) {
                    return <img src={preview} width='80' height='80' />
                }
                else {
                    if (response['data'] && response['data']['data'][field])
                        return <img src={`${process.env.REACT_APP_SERVER_URL}storage/logo/${response['data']['data'][field]}`} width='80' height='80' />
                    else
                        return " "

                }
                break;

            case 'favicon':
                if (previewFavicon) {
                    return <img src={previewFavicon} width='80' height='80' />
                }
                else {
                    if (response['data'] && response['data']['data'][field])
                        return <img src={`${process.env.REACT_APP_SERVER_URL}storage/${field}/${response['data']['data'][field]}`} width='80' height='80' />
                    else
                        return " "

                }
                break;

            default:


        }


    }

    return (
        <div className="page-body">
            <Container>
                <Row>
                    <Col sm={12}>
                        <Card className=" title-line">
                            <CommonCardHeader title={'General Setting'} />
                            <CardBody className="custom-input">
                                <Form onSubmit={handleSubmit(saveForm)}>
                                    <Row className="g-3">
                                        <Col xs={6}>
                                            <Label htmlFor="first-name">Company Name</Label>
                                            <Input id="first-name" type="text" placeholder="Company Name"
                                                {...registerRs("company_name")}

                                            />

                                            {errors.company_name && <p className="form-error">Field is required</p>}

                                        </Col>

                                        <Col xs={6}>
                                            <Label htmlFor="exampleFormControlInput1">Email Address</Label>
                                            <Input id="exampleFormControlInput1" className={errors.company_email ? 'is-invalid' : ''}
                                                placeholder="support@wemail.com"  {...registerRs("company_email")}

                                            />

                                            {errors.company_email && <p className="form-error">Email is invalid</p>}
                                        </Col>
                                        <Col xs={4}>
                                            <Label htmlFor="exampleFormControlInput1">Phone No. </Label>
                                            <Input id="exampleFormControlInput1" type="number" placeholder=""
                                                className={errors.contact_no ? 'is-invalid' : ''}
                                                {...registerRs("contact_no", {
                                                    pattern: /^[6-9]\d{9}$/
                                                })}

                                            />

                                            {errors.contact_no && <p className="form-error">Phone Number is invalid </p>}
                                        </Col>
                                        <Col xs={4}>
                                            <Label htmlFor="exampleFormControlTextarea12" >Page Title</Label>
                                            <Input type="text" id="exampleFormControlTextarea12" rows="3" {...registerRs("page_title")} />
                                        </Col>
                                        <Col xs={4}>
                                            <Label htmlFor="exampleFormControlTextarea1" >GSTIN</Label>
                                            <Input type="text" id="exampleFormControlTgst"   {...registerRs("gstin")} />
                                        </Col>
                                        <Col xs={6}>
                                            <Label htmlFor="exampleFormControlTextarea1" >Address</Label>
                                            <Input type="textarea" id="exampleFormControlTextarea1" rows="3"  {...registerRs("address")} />
                                        </Col>

                                        <Col xs={6}>
                                            <Label htmlFor="exampleFormControlTextarea12" >Google Map Code</Label>
                                            <Input type="textarea" id="exampleFormControlTextarea12" rows="3" {...registerRs("google_map_code")} />
                                        </Col>


                                        <Col xs={6}>
                                            <Label htmlFor="formFile">Logo <small >(Max Size:1MB)</small></Label>
                                            <Input type="file"  {...registerRs("logo")} onChange={handleFileChange} />
                                            {errors.logo && <p className="form-error" dangerouslySetInnerHTML={{ __html: `<span>${errors.logo?.message}</span>` }} />}
                                            {renderImage('logo')}

                                        </Col>
                                        <Col xs={6}>
                                            <Label htmlFor="formFile">Favicon  <small >(Max Size:500KB)</small></Label>
                                            <Input type="file"  {...registerRs("favicon")} onChange={handleFaviconFileChange} />
                                            {errors.favicon && <p className="form-error" dangerouslySetInnerHTML={{ __html: `<span>${errors.favicon?.message}</span>` }} />}
                                            {renderImage('favicon')}

                                        </Col>

                                        <Col xs={4}>
                                            <Label htmlFor="exampleFormControlInput1">Facebook</Label>
                                            <Input id="exampleFormControlInput1"  {...registerRs("facebook")} />
                                        </Col>
                                        <Col xs={4}>
                                            <Label htmlFor="exampleFormControlInput1">Youtube</Label>
                                            <Input id="exampleFormControlInput1"  {...registerRs("youtube")} />
                                        </Col>
                                        <Col xs={4}>
                                            <Label htmlFor="exampleFormControlInput1">Twitter</Label>
                                            <Input id="exampleFormControlInput1"   {...registerRs("twitter")} />
                                        </Col>
                                        <Col xs={6}>
                                            <Label htmlFor="exampleFormControlInput1">Instagram</Label>
                                            <Input id="exampleFormControlInput1"   {...registerRs("instagram")} />
                                        </Col>
                                        <Col xs={6}>
                                            <Label htmlFor="exampleFormControlInput1">Whatsapp</Label>
                                            <Input id="exampleFormControlInput1" type="number" {...registerRs("whatsapp", {
                                                pattern: /^[0-9\-\(\)\, ]+$/
                                            })} />
                                        </Col>




                                        <Col xs={12}>
                                            <FormGroup check={false} switch>
                                                <Input type="checkbox" id="flexSwitchCheckDefault"  {...registerRs("show_google_reviews")} />
                                                <Label htmlFor="flexSwitchCheckDefault" >Show Google Reviews </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12}>
                                            <Btn color="primary" size={'sm'} className="pull-right btn-square">
                                                {isLoading ? 'Loading' : 'Submit'}</Btn>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </div>


    )
}
