import { useDelete } from "@refinedev/core";
import { useCallback, useState } from "react"
import { Button, Popover, PopoverBody, PopoverHeader } from "reactstrap"
import { Btn } from "../../../AbstractElements";
import { toast } from "react-toastify";

export function DeleteButton({item_id,deleteCallback}:any){
    const [isOpen,setOpen]=useState(false)
  
   
    const toggle=()=>setOpen(!isOpen)
    return <>
    <Button onClick={toggle}  id={`id_${item_id}`} color='danger'  outline={true} >
            <i className='fa fa-trash'></i></Button>
        <Popover
         flip
             placement="bottom"
            target={`id_${item_id}`}
            toggle={toggle}
             trigger="click"
             isOpen={isOpen}
        >
            <PopoverHeader color='danger'>
                Are You Sure ?
            </PopoverHeader>
            <PopoverBody>
                <div className="d-flex gap-2">
                    <Btn color="primary" size="sm" onClick={(e)=>deleteCallback(item_id)}>
                       Yes</Btn>
                    <Btn color="danger" size="sm"  onClick={toggle}>No</Btn>

                </div>

            </PopoverBody>
        </Popover>
    </>

}