import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_SERVER_URL+'api' }),
  endpoints: (builder) => ({
    getMenu: builder.query<any,void>({
      query: () => `cms/get_menu`,
    }),
    getCmsSetting: builder.query<any,void>({
      query: () => `cms/get_cms_setting`,
    }),
    saveMenu: builder.mutation({
      query: (data) => ({
        url: `cms/save_menu`,
        method: 'POST',
        body: { menu:data },
      })
    }),
    saveCmsSetting: builder.mutation({
      query: (formdata) => ({
        url: `cms/save_cms_setting`,
        method: 'POST',
        formData: true,
        body: formdata,
      }),
    }),
    saveSeoSetting: builder.mutation({
      query: (formdata) => ({
        url: `cms/save_cms_setting`,
        method: 'POST',
        formData: true,
        body: formdata,
      }),
    }),
    saveArea: builder.mutation({
      query: (formdata) => ({
        url: `areas`,
        method: 'POST',
        formData: true,
        body: formdata,
      }),
    }),
    savePlan: builder.mutation({
      query: (formdata) => ({
        url: `plans`,
        method: 'POST',
        formData: true,
        body: formdata,
      }),
    }),
    updateArea: builder.mutation({
      query: ({data,id}) =>{
        console.log('looo',data,id)
        return {
          url: `areas/${id}`,
          method: 'PUT',
        
          body: data,
        }
      },
    }),
    updatePlan: builder.mutation({
      query: ({data,id}) =>{
       
        return {
          url: `plans/${id}`,
          method: 'PUT',
        
          body: data,
        }
      },
    }),
  })
});
export const { useGetMenuQuery, useSaveMenuMutation, useGetCmsSettingQuery,useSaveCmsSettingMutation,
  useSaveSeoSettingMutation,
  useSaveAreaMutation,
  useSavePlanMutation,
  useUpdateAreaMutation,
  useUpdatePlanMutation
 } = api;
